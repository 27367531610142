/* .App {
   text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
} */

.App-link {
  color: #61dafb;
}


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'OpenSans','Tahoma', 'Geneva', 'Roboto medium', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.csslogo {
 background: #ffc107;
 width: 80px;
 height: 80px;
 border-radius: 50%;
 text-align: center;
 margin-left: 35%;
}

.csslogo::before {
  font-weight: 700;
  font-size: 50px;
  color: rgb(18, 18, 19);
  content: "L";
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'OpenSans','Tahoma', 'Geneva', 'Roboto medium', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
-webkit-font-smoothing: antialiased;
-moz-osx-font-smoothing: grayscale;
}

.vertical-center {
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.ver-hori-center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 5%;
  height: 50%;
  width: 60%;
 
}

.ldiv {
  width: 68%;

}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loading-spinner {
  width: 50px;
  height: 50px;
  border: 10px solid #f3f3f3; /* Light grey */
  border-top: 10px solid #383636; /* Black */
  border-radius: 50%;
  animation: spinner 1.5s linear infinite;
}

* {
  box-sizing: border-box;
}


.flg {
  /* left: 0;
  position: absolute;
  top: calc(50% - 0.5em);
  width: 30px; */
  margin-right: 4px;
}

.btmimg { /*height: 53px;*/
  width:140px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding-right: 20px;}

.trbadge:hover {
  background-color: #e0b90b;
}

.addrdiv {
margin-top: 5px;
    /* padding: 2px; */
    display: block;
}
.lbl-primary{
background-color: #1d5481;
color: white;
max-width: fit-content;
padding: 5px;
font-size: 14px;
}
.heading {
font-size: 20px;
margin-right: 10px;
}

.amain {
  font-weight: 600;
}

.amain, .header {
  font-size: 19px;
}

.badge:hover {
  background-color: #6d6d6d;
}

.srbadge:hover {
  background-color: #1d1717;
}
.srbadge{
  background-color:#4b4b4b;
  color:white;
  float: left;
    /* display: inline-block; */
    /* overflow: hidden; */
    /* align-items: self-start; */
    padding: 5px;
    margin-right: 10px;
    margin-top: 10px;
}
.phweb{ margin-right: 5px; }

.page-link { background-color: #a5d7e7}
.page-link:hover { background-color: #022bb4; color: white;}

.navmain { max-height: 120px;}
.sbtn { max-width: 160px; width: auto; }
.img-logo {
width: 150px;
margin-left: -35px;
/* padding-right: 25px; */
}
.logo{
  font-size: 35px;
  font-weight: 400;
  font-family: 'Segoe UI', 'OpenSans', Tahoma, 'Roboto',Geneva, Verdana, sans-serif;
  /* margin-right: 30px; */
  text-align: left;
  /* margin-bottom: 1px; */
  /* padding-bottom: 8px; */
  padding-right: 30px;
  text-shadow: 0 0 black;
  /* width: 150px; */
  /* background-image: url("https://static-law.s3.us-west-2.amazonaws.com/lawyerz-logo-wide-100.png"); */
}

div {
  flex-shrink: 1;
}

h3 { font-size:16px; }

.ph-outer {
  display: flex;
}
.phdiv {
  background-color: #f9f9f9;
  color:#000000;
  display: flex;
  overflow:scroll;
  margin-left:12px;
  /* padding:2px; */
  align-items: flex-end;
  /* border-bottom-right-radius: 5px; */
  /* border-top-color: #800200; */
  /* border-bottom-left-radius: 5px; */
  /* border-top-width: 5px; */
  word-wrap: break-word;
  text-align: center;
}

/* scrollbar */

/* width */
::-webkit-scrollbar {
  height: 5px;
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgb(54, 53, 53); 
  border-radius: 3px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  
  background:#1d5481;
  border-radius: 3px;
  height: 50px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(39, 39, 39); 
}


/* .phdiv:hover { background-color: #e9e9e9; } */
.phname {
  display:block; text-align:center;
  align-items: top;
}
.phnum {
  margin-top: 2px;
  margin-bottom: 5px;
  margin-right: 5px;
  display: block;
  text-align: center;
}

#qry:before {
  content: "•";
  font-size: 18px;
  font-weight:900;
  color: #0d6efd;
  text-shadow: #0d3bd3 0 0 3px;
  margin:0 8px;
}

.block { display: inline-block;}
.spacer { flex-grow: 1; }
.break { flex-basis: 100%; height:0;}
.sbox-out {

  display: inline-block;
  /* margin-top:7px; */
  width: auto;
  min-width: 225px;
  margin-right: auto;
}

.ftrimgdiv { background-color:#ffc107; padding:2px; border-bottom: #1d5481;
  border-bottom-style:solid; border-bottom-width: 2px; padding-top:4px; float: right; 
  width: 100%; margin-top:14px;
}

.ftrdiv { text-align: right; display: flex; margin-right: 5px; float: right;  
}

.pnl { font-weight: 700;
  font-size: 14px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'OpenSans', 'Tahoma', 'Geneva', Verdana, sans-serif; }
.phitemdiv {
 /* margin-right: 3px; */
  word-wrap: break-word;
  text-align: left;
  display: flex;
  font-size: 14px;
  font-weight: 500;
  /* align-items: center; */
  text-align: left;
  vertical-align: top;
  min-width: 140px;
  min-height: 75px;
  margin-right: 4px;
  /* margin-bottom: 5px; e9ecef*/
  justify-content:space-between; 
  flex-direction: column;
  background-color: #fcfbfc;
  border-bottom-color: black;
  /* border-bottom-width: 5px; */
  border-bottom-style: inset;
  /* border-block-color: inherit; */
  /* border-top: coral; */
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
  margin-bottom: 8px;
}
.phitemdiv:hover {
  /* font-size:16px;  */
  /* width: 145x;
  height: 130px; */
  background-color:#f5f5f5; 
   border-style: outset;  
  cursor: pointer; 
  /* padding:5px; */
  /* font-weight: 700; */
 /* margin-left:5px; 
margin-right: 5px; */
}

.phnum:hover {
  /* font-size:14px; */
   font-weight:900;
}
.trbtn {
  padding:0px;
  margin-left:3px;
  font-size:14px;
}

.nmsg{ font-size: 16px; font-family: -apple-system, BlinkMacSystemFont,'Segoe UI', 'OpenSans', 'Tahoma', 'Geneva', Verdana, sans-serif; 
padding: 2px; margin: 2px;
}
 .bmsg{ /*font-size: 16px; */
  font-family: -apple-system, BlinkMacSystemFont,'Segoe UI', 'OpenSans', 'Tahoma', 'Geneva', Verdana, sans-serif;  
  padding: 2px; font-weight: 640;
  }

.mtop { margin-bottom: 20px;}
.trbadge {
background-color:#ffc107;
color:rgb(0, 0, 0);
float: left;
  /* display: inline-block; */
  /* overflow: hidden; */
  /* align-items: self-start; */
  padding: 1px;
    margin-right: 8px;
    margin-top: 10px;
    border-radius: 5px;
}


.addr {
  margin-bottom: 8px;
  margin-top: 8px;
  font-size: 14px;
  font-weight: 500;
}

.trdiv{
  margin-left: 10%;
  width: 450px;
}


.topdiv {
  width: 95%;
  min-height: 350px;
}
.asub {
  font-size: 16px;
  font-weight: 500;
}

.hdr {
  font-size: 30px;
  font-weight: 600;
  line-height: 120%;
  margin-top: 10px;
  margin-bottom: 15px;
}

.sboxdiv {
  margin-left: 15%;
  margin-top: 5px;
}

.rsdiv {
  /* align-items: center; */
  margin-left: 180px;
  max-width: 850px;
  min-height: 250px;
}
@media (max-width: 1300px) {
.rsdiv {   margin-left: 180px; }
}
@media (max-width: 1121px) {
  .rsdiv {   margin-left: 180px; }
}

.topmast { height: 4px; background-color: #800200;}
.tags {display: inline-block; padding:5px}
.pwdiv { display: flex;}
.crd {
  border: 1px solid rgba(170, 142, 142, 0.125);
  padding: 0;
  margin-bottom: 2px;
}
.crditem { padding: 1px; }
.sbox {
  width: 60%;
  float: left;
  margin-right: 25px;
  font-size: 16px;
  font-weight: 500;
  border: #282c34 1px;
  /* margin-left: 8%; */
}

.imdiv { align-items: center;
  display: flex;}

.tline {
  display: flex;
  margin-bottom: 3px;
}
.hdrdiv {
  padding: 2px;
  margin-left: 3px;
  margin-top: 5px;
  height: fit-content;
  width: fit-content;
}
.rdiv{
  width: 32%;
}

.trtags { display: inline-block;
  margin-left: 20%;
  margin-top:10px; }

.text-left { text-align: left !important;}
.avatar {
  vertical-align: middle;
  width: 35px;
  height: 35px;
  border-radius: 50%;
}
h4 {
  font-size:21px;
}

.badge {padding: 3px; }

.rtgtxt { font-weight: 400; margin-top:2px;}

/* ratings styles */


.checked {
  color: orange;
}

.blchk { color: rgb(72 70 70); }
/* Three column layout */
.side {
  float: left;
  width: 20%;
  margin-top:0px;
  font-weight: 500;
}

.side-right {
  float: left;
  width: 25%;
  margin-top:0px;
  text-align: right;
  font-weight: 500;
}

.middle {
  margin-top:8px;
  float: left;
  width: 55%;
}

/* Place text to the right */
.right {
  text-align: right;
}

/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}

/* The bar container */
.bar-container {
  width: 100%;
  background-color: #f1f1f1;
  text-align: center;
  color: white;
}

.bardiv { display: block; font-size:12px;}
/* Individual bars */
.bar-5 {width: 62%; height: 5px; background-color: #198754;}
.bar-4 {width: 46%; height: 5px; background-color: #0d6efd;}
.bar-3 {width: 36%; height: 5px; background-color: #0dcaf0;}
.bar-2 {width: 26%; height: 5px; background-color: #ff9800;}
.bar-1 {width: 20%; height: 5px; background-color: #bb2d3b;}

.sbox-nav {

  /* width: 80%; */
  /* width:325px; */
  float: left;
  /* margin-right: 10px;
  margin-left:15px; */
  margin-right: 5px;
  font-size: 16px;
  font-weight: 500;
  border: 2px #282c34;
  /* margin-bottom: 10px; */
  min-width: 360px;
  width: max-content;
}

.klink {
  background-color: transparent!important;
  border: 0!important;
  color: blue!important;
  cursor: pointer!important;
  display: inline-block!important;
  margin: 2px!important;
  /* outline: none!important; */
  padding: 5px!important;
  /* text-decoration: underline!important; */
  font-size: 24px;
  font-weight: 300;
  /* line-height: 120%; */
}

.klink:hover {
text-decoration: underline!important;
font-weight: 800;
font-size: 35px;
background-color: #dbefff;
color: #002161;
}

.navitem {font-size:16px; font-weight: 600; padding: 0px; }

.logo:hover, .navitem:hover { font-weight:700; }

.rounded-pill { margin-right: 2px; margin-bottom: 2px; background-color: #515a62 !important; }
.rounded-pill:hover {background-color: #1d1717;}

.abtdiv {
  width:85%;
  margin:5%;
  text-align: left;
 padding: 20px;
 word-wrap: break-word;
 }
.abt {
  width:90%;
  word-wrap: break-word;
  word-spacing: inherit;
  letter-spacing: inherit;
  margin: 15px;
  font-size: 18px;
  line-height: 1.75em;
  padding: 15px;
  text-align: left;}
.nopad { padding: 0px; margin: 0px;}
  .ftr-nav-link { margin-right: 10px; padding:1px;}
  .ftrnavitem { padding:2px; text-align: right; margin-right: 10px; margin-top:5px;}
  
  .wldiv { margin:0px; padding:3px;
    background-color:#1d5481;}
  .wlmsg { color:#f1f1f1 ;  margin-left: 5px; margin-right: 25px;}
  .wlmsg-link {
    color:#f0e5e5;
    font-family: -apple-system, BlinkMacSystemFont,'Segoe UI', 'OpenSans', 'Tahoma', 'Geneva', Verdana, sans-serif;  
  padding: 2px; font-weight: 640;
  }
  .wlmsg-link:hover {
    color:#fff4f4; }

  .wlcls { font-weight: 800; }

  .ftrnavitem:hover { font-weight: 700;}


@media screen and (min-width: 300px) and (max-width: 767px) {
  body {
    font-size:12px;
  }

.ftrdiv { margin-right: 0px;}  
.topdiv {
  width: 98%;
  /* min-height: 350px; */
}

  .img-logo { width:100px; margin-left: -20px;}
 .btmimg { width:90px; padding-right: 0px; }

   .rsdiv { margin-left: 5%;}
    h4 {
      font-size:16px;
    }

    #qry:before { font-size: 16px; }


    .avatar { width: 35px; height: 35px;}

    .amain, .header {
      font-size: 16px;
    font-weight: 700;
    }

    .addr {
      font-size: 12px;
    }
    .asub {
      font-size: 13px;
      font-weight: 600;
    }
    .lbl-primary {
      font-size: 12px;
    }
    .rdiv {
      font-size:12px
    }
    .badge {
      font-size: 11px;
      display: block;
      padding: 1px;
      margin-left: 0px;
    }
    .addrdiv { display: inline-block; margin-top: 2px;}
    .fa { font-size: 14px; }
  .sbox-page {
      width: 55%;
      float: left;
      margin-right: 25px;
      font-size: 12px;
      font-weight: 500;
      border: 1px #282c34;
      margin-bottom: 12px;
  }
  .trbadge {
      padding: 1px;
      display: block;
      margin-right: 5px;
      margin-top: 5px;
      border-radius: 5px;
  }
  .trtags {margin-top:10px; margin-left: 7%;}

  h3 { font-size: 14px; }
 .resdiv { width: 98%;}
  .phweb { margin-right:1px;}

/*btn-warning btn-lg btn-link-warning */

.sboxdiv { margin-left: 2%; }
.sbox{
  width: 68%;
    float: left;
    margin-right: 3%;
    font-weight: 500;
    border: #282c34 1px;
  font-size: 14px;
}
.btn-lg {
  padding: 8px;
  height: 45px;
  font-size: 16px;
}

.p-3 { padding:5px !important; margin-top: 10px; }

.sbox-nav {

  float: left;
  /* margin-right: 3px;
  margin-left: 0px; */
  margin:0px;
  font-size: 12px;
  font-weight: 500;
  border: 1px #282c34;
  /* margin-bottom: 10px; */
  min-width: 185px;
  width: auto;

}

 .phdiv { margin-left:5px}
 .pname, .phitemdiv {font-size: 13px;}
 .pnl {font-size: 13px; font-weight: 700;}
 .phnum { margin-right:1px; margin-bottom: 5px;}
 .phitemdiv .fa {font-size: 12px;}
 .phitemdiv { min-width: 114px; min-height: 72px;
  margin-right: 3px;}

 .nmsg{ font-size: 14px; font-family: -apple-system, BlinkMacSystemFont,'Segoe UI', 'OpenSans', 'Tahoma', 'Geneva', Verdana, sans-serif; 
  padding: 2px; margin: 2px;
  }
.navitem { margin: 0px; padding: 0px; font-size: 11px; min-width: 40px;}
.logo { font-size: 17px; padding-right: 0px; margin: 0px; padding: 0px;}

.logo:hover { font-size:20px; font-weight: 700;}
.navitem:hover {font-size:13px; font-weight: 700;}


.side-right {
  width: 25%;
}
.side{
  width: 25%;
}
.middle{
  width: 50%;
}

}

/* Responsive layout - make the columns stack on top of each other instead of next to each other */
@media (max-width: 299px) {
  .side, .side-right {
    width: 100%;
  }
  .middle {
    display: none;
  }
}

/* @keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */
